
export const path = {
  // main path
  HOME_PATH: "/",
  NOT_FOUND_PATH: "not-found",
  KQXN_PATH: "kqxn",
  KQXN_RESULT_PATH: 'result',
  CATEGORY_PATH: "category",
  POST_PATH: "Post",
  EMPLOYEE_PATH: "employee",
  // auth path
  LOGIN_PATH: "login",
  SEARCH_PATH:'search',
  // admin path
  ADMIN_PATH: "admin",
  ADMIN_PATH_HOME: "dashboard",
};
export const ACTION = {
  CATEGORY: "category",
  DEPARTMENT: "department",
  EMPLOYEE: "employee",
  MENU: "menu",
  POST: "post",
  QNA: "qna",
  QUALITY: "quality",
  SLIDE: "slide",
};

export const LIST_HEADER_TABLE_SLIDE = [
  {
    id: "1",
    label: "Ảnh",
    dataIndex: "imagePath",
    type: "upload",
  },
  {
    id: "3",
    label: "URL",
    dataIndex: "url",
    type: "input",
  },
];
export const LIST_HEADER_TABLE_DEPARTMENT = [
  {
    id: "1",
    label: "Mã",
    dataIndex: "code ",
    type: "input",
    required: true,
  },
  {
    id: "2",
    label: "Tên",
    dataIndex: "name ",
    type: "input",
    required: true,
  },
];
export const LIST_HEADER_TABLE_EMPLOYEE = [
  {
    id: "1",
    label: "Mã",
    dataIndex: "code ",
    type: "input",
    required: true,
  },
  {
    id: "2",
    label: "Tên",
    dataIndex: "name ",
    type: "input",
    required: true,
  },
  {
    id: "3",
    label: "Vị trí",
    dataIndex: "position",
    type: "input",
  },
  {
    id: "4",
    label: "Chuyên môn",
    dataIndex: "departmentId",
    type: "select",
  },
  {
    id: "5",
    label: "Ảnh",
    dataIndex: "imagePath",
    type: "upload",
  },
];
export const LIST_HEADER_TABLE_QNA = [
  {
    id: "1",
    label: "Câu hỏi",
    dataIndex: "question",
    type: "ckeditor",
  },
  {
    id: "2",
    label: "Câu trả lời",
    dataIndex: "answer",
    type: "ckeditor",
  },
  {
    id: "3",
    label: "Tên",
    dataIndex: "name",
    type: "input",
    required: true,
  },
  {
    id: "4",
    label: "Email",
    dataIndex: "email",
    type: "input",
    required: true,
  },
  {
    id: "5",
    label: "Số điện thoại",
    dataIndex: "phone",
    type: "input_number",
  },
  {
    id: "6",
    label: "Ghi chú",
    dataIndex: "note",
    type: "input",
  },
];
export const LIST_HEADER_TABLE_RATING = [
  {
    id: "1",
    label: "Tên",
    dataIndex: "name",
    type: "input",
    required: true,
  },
  {
    id: "2",
    label: "Email",
    dataIndex: "email",
    type: "input",
    required: true,
  },
  {
    id: '3',
    label: 'Số điện thoại',
    dataIndex: 'phone',
    type: 'input_number',
  },
  {
    id: "4",
    label: "Đánh giá",
    dataIndex: "rating",
    type: "number",
  },
  {
    id: "5",
    label: "Ghi chú",
    dataIndex: "note",
    type: "input",
  },
];
export const LIST_HEADER_TABLE_POST = [
  {
    id: "1",
    label: "Mã",
    dataIndex: "code",
    type: "input",
  },
  {
    id: "2",
    label: "Tên",
    dataIndex: "name",
    type: "input",
  },

  {
    id: "6",
    label: "Danh mục",
    dataIndex: "categoryId",
    type: "select",
    required: true,
  },
  {
    id: "5",
    label: "Loại file",
    dataIndex: "fileType",
    type: "select",
  },
  {
    id: "4",
    label: "File",
    dataIndex: "filePath",
    type: "upload",
  },

  {
    id: "2",
    label: "URL Video",
    dataIndex: "urlVideo",
    type: "input",
  },

  {
    id: "7",
    label: "Mô tả",
    dataIndex: "description",
    type: "input",
  },
  {
    id: "9",
    label: "Nội dung",
    dataIndex: "content",
    type: "ckeditor",
  },
];
export const LIST_HEADER_TABLE_MENU = [
  {
    id: "1",
    label: "Mã",
    dataIndex: "code",
    type: "input",
  },
  {
    id: "2",
    label: "Tên",
    dataIndex: "name",
    type: "input",
  },
  {
    id: "3",
    label: "URL",
    dataIndex: "url",
    type: "input",
  },
  {
    id: "4",
    label: "Mục gốc",
    dataIndex: "parentId",
    type: "select",
  },
  {
    id: "5",
    label: "Cấp",
    dataIndex: "level",
    type: "select",
  },
  {
    id: "6",
    label: "Vị trí",
    dataIndex: "positionType",
    type: "select",
  },
  {
    id: "7",
    label: "Thứ tự",
    dataIndex: "sortOrder",
    type: "number",
  },
  {
    id: "8",
    label: "Ảnh",
    dataIndex: "filePath",
    type: "upload",
  },

];
export const LIST_HEADER_TABLE_CATEGORY = [
  {
    id: "1",
    label: "Mã",
    dataIndex: "code",
    type: "input",
  },
  {
    id: "2",
    label: "Tên",
    dataIndex: "name",
    type: "input",
  },
  {
    id: "5",
    label: "Cấp",
    dataIndex: "level",
    type: "select",
  },
  {
    id: "3",
    label: "Mục gốc",
    dataIndex: "parentId",
    type: "select",
  },
  {
    id: "6",
    label: "Hiện trang",
    dataIndex: "isShowPage",
    type: "checkbox",
  },
];
